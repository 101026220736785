import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import LearnMore from "../templates/learnMore";
import private_office_4 from "../images/private_office_5.jpg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Daily Offices"
      />
      <LearnMore
        title={`An office only when you need one`}
        cta={`Get in touch`}
        image={private_office_4}
      >
        <div>
          <p className="text-xl">
            Our daily office spaces allow you to utilise a fully furnished
            private office space when you need it. It is our goal at
            Collective_100 to be able to provide a flexible service that you can
            access when you need it. This includes offering not only flexible
            desk options but also flexible offices enabling you to book an
            office on the day you need it.
          </p>
        </div>
        <div>
          <p className="text-xl pt-8 md:pt-0">
            For teams of two to six people, a daily room can be the perfect
            option to boost your workflow, allowing for private collaboration
            and the opportunity for open creativity. No matter what your needs
            are, we are here to operate as your home base open from Monday to
            Friday.{" "}
          </p>
        </div>
      </LearnMore>
    </Layout>
  );
}

export default IndexPage;
